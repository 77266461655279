<template>
  <div>
    <div
      v-if="!isDatabaseFetchDone"
      class="columns is-vcentered px-5"
    >
      <div class="is-10 column">
        <b-skeleton height="30" />
        <b-skeleton height="50" />
      </div>
      <div class="is-2 column">
        <b-skeleton height="30" />
      </div>
    </div>

    <NoResults
      v-else-if="databaseStore.databases.length === 0"
      banner-label="No databases yet, create one now."
      button-label="New database"
      :button-handler="openNewDatabaseModal"
    />

    <b-table
      v-else
      :data="databaseStore.databases"
      class="tab-table-layout"
      @click="rowClicked($event)"
    >
      <b-table-column
        v-slot="{ row }"
        label="Name"
        field="name"
      >
        {{ row.name }}
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        label="Created at"
        field="created_at"
      >
        {{ row.created_at }}
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        label="Last modified at"
        field="modified_at"
      >
        {{ row.updated_at }}
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        label=""
        cell-class="has-text-right"
      >
        <b-button
          size="is-small"
          class="app-dropdown-btn"
          @click.stop="toggleActionDropdown(row.id)"
        >
          <b-icon
            icon="dots-horizontal"
            size="is-size-6"
            class="app-dropdown-icon"
          />
        </b-button>
        <b-dropdown
          :ref="`moduleActionDropdown${row.id}`"
          aria-role="list"
          position="is-bottom-left"
          class="app-action-dropdown"
          append-to-body
        >
          <b-dropdown-item
            aria-role="listitem"
            class="is-flex is-align-items-center"
            @click="goToDatabase(row.id)"
          >
            <b-icon
              icon="eye"
              type="is-black"
              custom-size="mdi-22px"
              class="mr-2"
            />
            View
          </b-dropdown-item>

          <b-dropdown-item
            aria-role="listitem"
            class="is-flex is-align-items-center"
            @click="editDatabase(row)"
          >
            <b-icon
              icon="pencil-outline"
              type="is-black"
              custom-size="mdi-22px"
              class="mr-2"
            />
            Edit
          </b-dropdown-item>

          <b-dropdown-item
            aria-role="listitem"
            custom
            class="is-flex is-align-items-center is-justify-content-center"
          >
            <b-button
              icon-left="delete-outline"
              size="is-small"
              type="is-danger is-light"
              class="rounded-8 w-full"
              @click.stop="confirmDelete(row)"
            >
              Delete
            </b-button>
          </b-dropdown-item>
        </b-dropdown>
      </b-table-column>
    </b-table>

    <BaseModal
      v-model="isEditDatabaseModalVisible"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Edit Datebase"
      aria-modal
    >
      <CardPopup
        v-if="isEditDatabaseModalVisible === true"
        title="Edit Datebase"
        @hide="isEditDatabaseModalVisible = false"
      >
        <template #body>
          <b-field
            label="Database name*"
            :type="databaseToBeEditedErrors.name? 'is-danger': ''"
            :message="databaseToBeEditedErrors.name? databaseToBeEditedErrors.name: ''"
          >
            <b-input
              v-model="databaseToBeEdited.name"
              type="text"
              placeholder="e.g. My awesome database"
              @keyup.native="validateDatabase('name')"
            />
          </b-field>
        </template>
        <template #footer>
          <div class="is-flex is-justify-content-space-between w-full">
            <b-button
              class="px-6 rounded-8 btn-primary-light"
              @click="isEditDatabaseModalVisible = false"
            >
              Cancel
            </b-button>
            <b-button
              type="is-primary"
              class="px-6 rounded-8"
              @click="updateDatabase()"
            >
              Update
            </b-button>
          </div>
        </template>
      </CardPopup>
    </BaseModal>

    <ConfirmDeleteModal
      v-model="isDeleteDatabaseModalVisible"
      :entity-name="databaseToBeDeleted.name"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Confirm Delete Database"
      aria-modal
      @delete="deleteDatabase()"
    />
  </div>
</template>

<script >
import { defineAsyncComponent, reactive, ref, getCurrentInstance, onMounted } from '@vue/composition-api';
import { fetchApplicationByIdService } from '@/services/application-service/applicationRequests';
import { deleteDatabaseService, updateDatabaseService } from '@/services/database-service/databaseRequests';
import { useDatabaseStore } from '@/modules/builder/store/databaseStore';
import { useRoute, useRouter } from '@/hooks/vueRouter';
import { useBuefy } from '@/hooks/buefy';
import BaseModal from '@/modules/core/components/generics/base-modal/BaseModal.vue';
import CardPopup from '@/modules/core/components/generics/base-modal/CardPopup.vue';
import * as Yup from 'yup';

//-- child components --//
const NoResults = defineAsyncComponent(() => import('@/modules/core/components/NoResults.vue'));
const ConfirmDeleteModal = defineAsyncComponent(() => import('@/modules/core/components/ConfirmDeleteModal.vue'));

//-- compose hooks --//
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const databaseStore = useDatabaseStore();
  const route = useRoute();
  const router = useRouter();
  const buefy = useBuefy();

  // @VUE3: do not use this function when migrating to vue 3
  const vm = getCurrentInstance();

  // -- Database Validation --//
  const DatabaseSchema = Yup.object().shape({
    name: Yup.string().trim().required('Database name is required')
  });
  const validateDatabase = async field => {
    try {
      await DatabaseSchema.validateAt(field, databaseToBeEdited);
      databaseToBeEditedErrors[field] = '';
    } catch (err) {
      databaseToBeEditedErrors[err.path] = err.message;
      return false;
    }

    // is db name alredy exists
    const isDBNameExists = databaseStore.databases.find(d => d.id != databaseToBeEdited.id && d.name.toLowerCase() === databaseToBeEdited.name.toLowerCase());
    if (isDBNameExists) {
      databaseToBeEditedErrors['name'] = 'Chosen database name exists. Please choose another';
      return false;
    }
  };
  const databaseToBeEditedErrors = reactive({
    name: ''
  });

  // Edit Database
  const isEditDatabaseModalVisible = ref(false);
  const databaseToBeEdited = reactive({
    id: '',
    name: ''
  });
  const editDatabase = item => {
    databaseToBeEdited.id = item.id;
    databaseToBeEdited.name = item.name;
    isEditDatabaseModalVisible.value = true;
  };
  const updateDatabase = async () => {
    try {
      await DatabaseSchema.validate(databaseToBeEdited, {
        abortEarly: false
      });
    } catch (err) {
      err.inner.reverse().forEach(error => {
        databaseToBeEditedErrors[error.path] = error.message;
      });
      return false;
    }
    // is db name alredy exists
    const isDBNameExists = databaseStore.databases.find(d => d.id != databaseToBeEdited.id && d.name.toLowerCase() === databaseToBeEdited.name.toLowerCase());
    if (isDBNameExists) {
      databaseToBeEditedErrors['name'] = 'Chosen database name exists. Please choose another';
      return false;
    }
    await updateDatabaseService(databaseToBeEdited.id, {
      name: databaseToBeEdited.name.trim()
    });
    const updatedDB = databaseStore.databases.find(d => d.id == databaseToBeEdited.id);
    if (updatedDB) {
      updatedDB.name = databaseToBeEdited.name.trim();
    }
    buefy.toast.open('Database updated!');
    isEditDatabaseModalVisible.value = false;
  };
  // -- Edit Database -- //

  // -- database modals logic --//
  const isDeleteDatabaseModalVisible = ref(false);
  const openNewDatabaseModal = () => {
    databaseStore.setDatabaseModalActivity(true);
  };

  //-- database fetch logic --//
  const applicationName = ref('');
  const isDatabaseFetchDone = ref(false);
  const fetchDatabases = async () => {
    try {
      await databaseStore.fetchDatabases(route.params.appId);
      isDatabaseFetchDone.value = true;
    } catch (err) {
      console.error(err);
    }
  };
  const fetchApplicationDetails = async () => {
    try {
      const response = await fetchApplicationByIdService(route.params.appId);
      applicationName.value = response.data.name;
    } catch (err) {
      console.error(err);
    }
  };
  onMounted(() => {
    fetchDatabases();
    fetchApplicationDetails();
  });

  //-- database delete logic --//
  const databaseToBeDeleted = reactive({
    id: '',
    name: ''
  });
  /**
   * @param {import('./types/appDatabases').IAppDatabase} item
   */
  const confirmDelete = item => {
    databaseToBeDeleted.id = item.id;
    databaseToBeDeleted.name = item.name;
    toggleActionDropdown(item.id);
    isDeleteDatabaseModalVisible.value = true;
  };
  const deleteDatabase = async () => {
    try {
      await deleteDatabaseService(databaseToBeDeleted.id);
      isDeleteDatabaseModalVisible.value = false;
      buefy.toast.open('Database deleted!');
      databaseToBeDeleted.id = '';
      databaseToBeDeleted.name = '';
      await fetchDatabases();
    } catch (err) {
      console.error(err);
    }
  };

  // -- database view logic --//
  /**
   * @param {import('./types/appDatabases').IAppDatabase} item
   */
  const rowClicked = eventPayload => {
    goToDatabase(eventPayload.id);
  };
  /**
   * @param {string} rowId
   */
  const toggleActionDropdown = rowId => {
    // @VUE3: use functional template ref in vue 3 instead (check offical vue 3 docs for info), vue 2 composition api plugin doesn't support them
    vm.proxy.$refs[`moduleActionDropdown${rowId}`].toggle();
  };
  /**
   * @param {string} id
   */
  const goToDatabase = id => {
    router.push('/application/' + route.params.appId + '/database/' + id);
  };
  return {
    databaseStore,
    validateDatabase,
    databaseToBeEditedErrors,
    isEditDatabaseModalVisible,
    databaseToBeEdited,
    editDatabase,
    updateDatabase,
    isDeleteDatabaseModalVisible,
    openNewDatabaseModal,
    isDatabaseFetchDone,
    databaseToBeDeleted,
    confirmDelete,
    deleteDatabase,
    rowClicked,
    toggleActionDropdown,
    goToDatabase
  };
};
__sfc_main.components = Object.assign({
  NoResults,
  BaseModal,
  CardPopup,
  ConfirmDeleteModal
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
.list:hover {
  opacity: 0.7;
  cursor: pointer;
}
</style>

<style lang="scss">
@import '~@/style/components.scss';
</style>
