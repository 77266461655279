// @ts-check
import { DATABASE_STORE_ID } from '@/constants/storeIds';
import { fetchDatabasesService } from '@/services/database-service/databaseRequests';
import { ref } from '@vue/composition-api';
import { defineStore } from 'pinia';

export const useDatabaseStore = defineStore(DATABASE_STORE_ID, () => {
    const isNewDatabaseModalActive = ref(false);
    /**
     * @param {boolean} isModalActive 
     */
    const setNewDatabaseModalActivity = (isModalActive) => {
        isNewDatabaseModalActive.value = isModalActive;
    };

    const isDatabaseModalActive = ref(false);
    const databases = ref([]);

    /**
     * @param {boolean} isModalActive
     */
    const setDatabaseModalActivity = (isModalActive) => {
        isDatabaseModalActive.value = isModalActive;
    };

    const isImportDatabaseModalActive = ref(false);
    /**
     * @param {boolean} isModalActive 
     */
    const setImportDatabaseModalActivity = (isModalActive) => {
        isImportDatabaseModalActive.value = isModalActive;
    };

    const fetchDatabases = async (application_id) => {
        try {
            const { data: { data } } = await fetchDatabasesService({
                application_id
            });
            databases.value = data;
        } catch (err) {
            console.error(err);
        }
    };

    return {
        isNewDatabaseModalActive,
        setNewDatabaseModalActivity,
        isDatabaseModalActive,
        setDatabaseModalActivity,
        isImportDatabaseModalActive,
        setImportDatabaseModalActivity,
        fetchDatabases,
        databases,
    };
});
